import { css } from "@emotion/react"
import React, { useContext, useEffect, useRef, useState } from "react"
import CategoryItem from "../../components/molecule/CategoryItem"
import CategoryTitle from "../../components/molecule/CategoryTitle"
import ProductItem from "../../components/molecule/ProductItem"
import Layout from "../../components/organism/Layout"
import CategoryProductContextProvider from "../../contexts/CategoryProductContext"
import { globalContext } from "../../contexts/GlobalContext"
import useCategory from "../../contexts/IndexContext/hooks/useCategory"
import { flexBoxCss } from "../../styles"
import { PAGE } from "../../values"
import Collapse from '@material-ui/core/Collapse';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const styles = require("./style.module.scss")

const CategoryProduct = () => {
  const [randomInt, setRandomInt] = useState<number>()
  const [isUnderTitle, setIsUnderTitle] = useState<boolean>(false)
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false)
  const isFirstRender = useRef<boolean>(true)
  const isMobile = useMediaQuery('(max-width: 1024px)')

  const backgroundImage = css`
    ${flexBoxCss("h", "center")}
    height: 228px;
    position: absolute;
    left: 0;
    padding-top: 40px;
    width: 100%;
    z-index: -1;

    @media (max-width: 1024px) {
      height: auto;
      position: relative;
      padding: 20px 10px;
      padding-bottom: 25px;
      width: 100vw;
      justify-content: flex-start;
    }
  `
  
  const noProductsText = css`
    font-size: 18px;
    padding-top: 40px;
  `

  const foldButton = css`
    display: none;
    @media (max-width: 1024px) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-size: 14px;
      text-align: right;
      padding: 8px 10px;
      border: 1px solid rgba(0,0,0,0.2);
      box-shadow: 0px 3px 3px rgba(0,0,0,0.1);
      border-radius: 5px;
      color: black;
    }
  `

  const categories = useCategory()
  const context = useContext(globalContext)
  const categoryProducts = context?.categoryProducts
  useEffect(() => {
    setRandomInt(Math.floor(Math.random() * 3))
  }, [categoryProducts?.category])
  
  useEffect(() => {
    const nowScrollHeightHandler = () => {
      let sectionTabs = document.getElementsByClassName("sectionTab")

      if (sectionTabs.length >= 1) {
        let relativeHeight = sectionTabs[0].getBoundingClientRect().top
        if (relativeHeight < 100) {
          setIsUnderTitle(true)
        } else {
          setIsUnderTitle(false)
        }
      }
    }
    if (isFirstRender.current) {
      isFirstRender.current = false
      window.addEventListener("scroll", nowScrollHeightHandler)
    }

    return () => window.removeEventListener("scroll", nowScrollHeightHandler)
  }, [])

  function handleCategoryProductSectionColor() {
    switch (randomInt) {
      case 0:
        return "#B596381A"
      case 1:
        return "#38B54C1A"
      default:
        return "#B538431A"
    }
  }

  return (
    <Layout nowPage={PAGE.CATEGORY_PRODUCT}>
      <CategoryProductContextProvider>
        <div className={"v-box " + styles["pageContainer"]}>
          <div className={styles["fullWidthContainer"]}>
            <Collapse in={!isCollapsed}>
              <div className={"h-box " + styles["categoryContainer"]}>
                {categories.data &&
                  categories.data!.map(item => (
                    <CategoryItem
                      category={item}
                      isSelected={categoryProducts?.category?.id == item.id}
                      key={item.id}
                      onClick={() => {
                        categoryProducts?.setCategory(item)
                        if(isMobile) {
                          console.log('called')
                          setIsCollapsed(true)
                        }
                      }}
                    />
                  ))}
              </div>
            </Collapse>
            <div onClick={() => setIsCollapsed(!isCollapsed)} css={foldButton}>
              <span style={{
                fontSize: 13,
                fontFamily: "나눔고딕",
                fontWeight: 700
              }}>코너 선택</span>
              {isCollapsed ? (
                <div style={{
                  display: "flex",
                  justifyContent: "center"
                }}>
                  <ExpandMoreIcon/>
                </div>
              ) : (
                <div style={{
                  display: "flex",
                  justifyContent: "center"
                }}>
                  <ExpandLessIcon/>
                </div>
              )}
            </div>
          </div>
          {categoryProducts?.category ? (
            <div className={styles["container"]}>
              <div
                css={backgroundImage}
                style={{
                  backgroundColor: categoryProducts.category.color !== null
                    ? "#" + categoryProducts.category.color
                    : handleCategoryProductSectionColor(),
                }}
                className="sectionTab"
              >
                <CategoryTitle category={categoryProducts?.category} />
              </div>
              <div className={styles["paddingContainer"]}>
                {categoryProducts?.categoryProducts.length == 0 ? (
                  <div css={noProductsText}>상품이 존재하지 않습니다</div>
                ) : (
                  <div className={styles["productContainer"]}>
                    {categoryProducts?.categoryProducts.map((product, index) => (
                      <ProductItem product={product} key={product.id} />
                    ))}
                  </div>
                )}
              </div>
            </div>
          ) : null}
        </div>
      </CategoryProductContextProvider>
    </Layout>
  )
}

export default CategoryProduct
