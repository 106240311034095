import React, { createContext } from "react"
import useCategoryProduct from "./hooks/useCategoryProduct"

interface ContextValueType {
  categoryProducts: ReturnType<typeof useCategoryProduct>
}

export const categoryProductContext = createContext<ContextValueType | null>(null)

interface CategoryProductContextProps {
  children: React.ReactNode
}

const CategoryProductContextProvider = ({ children }: CategoryProductContextProps) => {
  const categoryProducts = useCategoryProduct()
  return (
    <categoryProductContext.Provider
      value={{
        categoryProducts,
      }}
    >
      {children}
    </categoryProductContext.Provider>
  )
}

export default CategoryProductContextProvider
